<template>
    <cover></cover>
    <boredpanda-header></boredpanda-header>
    <router-view></router-view>
</template>

<script>
import {defineAsyncComponent} from 'vue';

export default {
    name: 'App',
    components: {
        Cover: defineAsyncComponent(() => import('@/components/Cover')),
        BoredpandaHeader: defineAsyncComponent(() => import('@/components/Header')),
    },
    created() {
        this.$loadScript('/js/quancast.js');
        this.$loadScript('/js/admiral.js');
        this.$loadScript('/js/comscore.js');
        this.$loadScript('https://scripts.webcontentassessor.com/scripts/331668635dbaff13aedcdcdb312d03b4e59f90dec1db97601f594b303f66dfab');
        this.$loadScript('https://z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=a0a121ed-4beb-4d3b-b165-9f025de28115');
        this.$loadScript('https://static.scroll.com/js/scroll.js');
    },
}
</script>

<style lang="scss">
abbr, address, article, aside, audio, b, blockquote, body, canvas, caption, cite, code,
dd, del, details, dfn, div, dl, dt, em, fieldset, figcaption, figure, footer, form,
h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend,
li, mark, menu, nav, object, ol, p, pre, q, samp, section, small, span, strong, summary,
table, tbody, td, tfoot, th, thead, time, tr, ul, var, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: 0 0;
}

body {
    font-family: Roboto, sans-serif;
    background: #f0f0f0;
    font-size: 16px;
    margin: 0;
    padding: 0;
    width: 100%;
    font-weight: 500;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.bp-container {
    max-width: 970px;
    width: 100%;
    margin: 0 auto;
}

a {
    text-decoration: none;
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: 0 0;
    color: #d74b1f;
}

.svg-icon {
    fill: currentColor;
    display: inline-block;
    vertical-align: text-top;
}

.clearfix {
    display: block;

    &:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
    }
}

.main {
    position: relative;
    margin-top: 25px;
    max-width: 970px;
    @media(max-width: 480px) {
        padding: 0;
        webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        margin-top: 0;
    }
}

.visible-desktop {
    display: block;
    @media(max-width: 979px) {
        display: none;
    }
}

.visible-mobile {
    display: none;
    @media(max-width: 979px) {
        display: block;
    }
}
</style>
