import { createWebHistory, createRouter } from 'vue-router';
// import Post from "@/views/Post";

function loadView(view) {
    return () => import(/* webpackChunkName: "[request]", webpackPreload: true */ `@/views/${view}`)
}

const routes = [
    {
        path: '/:catchAll(.*)',
        component: loadView('Post'),
        // component: Post,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;