import {createApp} from 'vue';
import router from './router';
import App from './App.vue';
import lazyPlugin from 'vue3-lazy';
import VueAnalytics from 'vue3-analytics';
import LoadScript from 'vue-plugin-load-script';

createApp(App)
    .use(router)
    .use(lazyPlugin, {
        loading: '/images/x.png',
        error: 'error.png',
    })
    .use(VueAnalytics, {
        id: 'UA-10000227-2',
    })
    .use(LoadScript)
    .mount('#app');
